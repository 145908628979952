<template>
	<div class="data-content">
		<div class="title-box">
			<span class="title">课程表日历</span>
			<ul>
				<li>
					<span></span>
					报名中
				</li>
				<li>
					<span></span>
					进行中
				</li>
				<li>
					<span></span>
					已结束
				</li>
			</ul>
		</div>
		<div class="top-box">
			<el-date-picker
				v-model="searchYearMonth"
				type="month"
				value-format="yyyy-MM"
				:editable="false"
				:clearable="false"
				@change="handleMonth"
				placeholder="选择月份">
			</el-date-picker>
			<el-button size="small" icon="el-icon-d-arrow-left" @click="handleMonths('prve')"></el-button>
			<el-button size="small" icon="el-icon-d-arrow-right" @click="handleMonths('next')"></el-button>
			<div class="top-right-box">
				<label class="label">培训中心</label>
				<el-select class="search-input" size="small" v-model="trainCenterId" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option v-for="(item,i) in trainCenterList" :label="item.name" :value="item.id" :key="i"></el-option>
				</el-select>
				<label class="label" v-if="eduTypeFlag">教育分类</label>
				<el-select v-if="eduTypeFlag" class="search-input" size="small" v-model="eduType" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option label="远程" value="1"></el-option>
					<el-option label="集合" value="2"></el-option>
				</el-select>
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			<table class="data-table" cellspacing="0" cellpadding="0">
				<thead>
					<tr>
						<th rowspan="2" width="80">培训中心</th>
						<th rowspan="2" width="160">课程名称</th>
						<th rowspan="2" width="100">分类</th>
						<th class="th-class" v-for="(day,d) in dayInfoList" :key="d">{{day}}</th>
					</tr>
					<tr>
						<th class="th-class" v-for="(week,wk) in weekInfoList" :key="wk">
							<span :style="week == '六' || week == '日' ? 'color:red;' : ''">{{week}}</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(td,i) in scheduleList" :key="i">
						<td>{{td.trainCenterName.replace('培训中心','')}}</td>
						<td class="train-td" @click="handleTrainCenter(td.id)">
							<el-tooltip class="item" effect="dark" placement="top">
								<div slot="content">{{td.courseName}}</div>
								<span class="td-span">{{td.courseName}}</span>
							</el-tooltip>
						</td>
						<td>
							<el-tooltip class="item" effect="dark" placement="top">
								<div slot="content">
									<span v-if="td.eduType == 1">远程</span>
									<span v-if="td.eduType == 2">集合</span>
									{{td.trainPlace ? '('+td.trainPlace+')' : ''}}
								</div>
								<div>
									<span v-if="td.eduType == 1">远程</span>
									<span v-if="td.eduType == 2">集合</span>
									{{td.trainPlace ? '('+td.trainPlace+')' : ''}}
								</div>
							</el-tooltip>
						</td>
						<td v-for="(course,c) in td.flagColor" :key="c" :class="course ? bgObj[course] : ''" @click="handleSign(td,course)"></td>
					</tr>
				</tbody>
			</table>
			<!-- <div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div> -->
		</div>
		<edit-data ref="editData" @getCourseTrainSchedule="getCourseTrainSchedule"></edit-data>
		<look-data ref="lookData"></look-data>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import LookData from '@/views/trainingManagement/questionManagement/components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		LookData,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchYearMonth: '',
			searchKey: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			eduType: '',//1线上2线下
			trainCenterId: '',
			trainCenterList: [],
			scheduleList: [],
			dayInfoList: [],
			weekInfoList: [],
			bgObj: {
				'b': 'b-bg',
				'j': 'j-bg',
				'e': 'e-bg'
			},
			eduTypeFlag: false
		}
	},
	created() {
		this.pageNo = 1;
		this.searchKey = '';
		this.searchYearMonth = this.$moment().format("YYYY") + '-' + this.$moment().format("MM");
		// 只有学员权限不显示教育分类
		let roleList = JSON.parse(window.sessionStorage.userInfo).roleList;
		roleList.forEach(item => {
			if([1,2,3,5,6].includes(item.type)) {
				this.eduTypeFlag = true;
			}
		});
		// 获取培训中心列表
		this.getTrainCenterList();
		// 获取课程数据
		this.getCourseTrainSchedule();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.eduType = '';
			this.trainCenterId = '';
			this.pageNo = 1;
			this.getCourseTrainSchedule();
		},
		// 获取培训中心列表
		getTrainCenterList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 100,
					code: 'trainCenter'
				},
				function (r) {
					if (r.code == "0") {
						that.trainCenterList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取课程数据
		getCourseTrainSchedule() {
			let that = this;
			that.$request.post(
				"getCourseTrainSchedule",
				true,
				{
					searchYearMonth: that.searchYearMonth,
					// pageNo: that.pageNo,
					// pageSize: that.pageSize,
					searchKey: that.searchKey,
					trainCenterId: that.trainCenterId,
					eduType: that.eduType
				},
				function (r) {
					if (r.code == "0") {
						that.scheduleList = r.data.courseInfo;
						that.dayInfoList = r.data.dayInfoList;
						that.weekInfoList = r.data.weekInfoList;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleMonth() {
			this.getCourseTrainSchedule();
		},
		handleMonths(type) {
			if(type == 'prve'){
				this.searchYearMonth = this.$moment(new Date(this.searchYearMonth)).subtract(1,'months').format('YYYY-MM');
			}else {
				this.searchYearMonth = this.$moment(new Date(this.searchYearMonth)).subtract('months',-1).format('YYYY-MM');
			}
			this.getCourseTrainSchedule();
		},
		// 新增/编辑/查看
		handleEditData(type,data) {
			this.$refs.editData.init(type,data);
		},
		// 报名
		handleSign(td,type) {
			if(type != 'b') {
				return
			}
			this.$confirm('是否报名此课程?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'signOffline',
					true,
					{
						userId: JSON.parse(window.sessionStorage.userInfo).id,
						trainId: td.id
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success('报名成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleTrainCenter(id) {
			this.$refs.lookData.init('check',{id:id,page:'schedule'});
		},
		handleSearch() {
			this.pageNo = 1;
			this.getCourseTrainSchedule();
		},
		handleSizeChange(val) {
			this.getCourseTrainSchedule();
		},
		handleCurrentChange(val) {
			this.getCourseTrainSchedule();
		}
	}
}
</script>
<style lang="scss" scoped>
.data-content{
	.title-box{
		margin-bottom: 10px;
		.title{
			font-size: 24px;
			font-weight: bold;
		}
		ul{
			float: right;
			li{
				list-style: none;
				display: inline-block;
				margin-left: 10px;
				span{
					display: inline-block;
					width: 15px;
					height: 15px;
					background-color: rgba(180, 216, 223, 1);
					vertical-align: -1px;
				}
				&:first-child{
					span{
						background-color: rgba(177, 251, 79, 1);
					}
				}
				&:last-child{
					span{
						background-color: rgba(204, 204, 204, 1);
					}
				}
			}
		}
	}
	.top-box{
		height: 32px;
		margin-bottom: 20px;
		.el-date-editor{
			/deep/ .el-input__inner{
				height: 32px;
				line-height: 32px;
			}
			/deep/ .el-input__prefix,
			/deep/ .el-input__suffix{
				top: -4px;
			}
		}
		/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
			width: 138px;
			height: 32px;
			line-height: 32px;
		}
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue,
	.detail-btn{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.f-r{
	float: right;
}
.data-table{
	table-layout: fixed;
	width: 100%;
	border-collapse:separate;
	thead{
		tr{
			th{
				line-height: 36px;
				text-align: center;
				background-color: rgba(249, 250, 253, 1);
				border-left: 1px solid #ddd;
				border-top: 1px solid #ddd;
				&:last-child{
					border-right: 1px solid #ddd;
				}
			}
			&:first-child{
				th{
					&:first-child{
						border-top-left-radius: 5px;
					}
					&:last-child{
						border-top-right-radius: 5px;
					}
				}
			}
			.th-class{
				font-size: 12px;
			}
		}
	}
	tbody{
		tr{
			td{
				line-height: 32px;
				color: #555;
				text-align: center;
				border-left: 1px solid #ddd;
				border-top: 1px solid #ddd;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				font-size: 13px;
				&:last-child{
					border-right: 1px solid #ddd;
				}
				.td-span{
					display: block;
					width: 100%;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			&:last-child{
				td{
					border-bottom: 1px solid #ddd;
				}
			}
			.b-bg{
				background-color: rgba(177, 251, 79, 1);
				cursor: pointer;
			}
			.j-bg{
				background-color: rgba(180, 216, 223, 1);
				cursor: pointer;
			}
			.e-bg{
				background-color: rgba(204, 204, 204, 1);
				cursor: pointer;
			}
			.train-td{
				cursor: pointer;
			}
		}
	}
}
.el-button+.el-button{
	margin-left: 0;
}
</style>

